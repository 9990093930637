import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './index.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import AboutUsAboutSection from '../../components/about/about-us-about-section';
import AboutUsBulletsSection from '../../components/about/about-us-bullets-section';
import AboutUsClosingSection from '../../components/about/about-us-closing-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getAboutUsImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath = getAboutUsImageEdge('trustaff-about-us-og').node
    .childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>About us - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Trustaff is much more than just a recruiting and staffing agency. We have a passion for the good business of putting people to work, and it shows every day in our relationships, teamwork, and the quality of our services."
        />
        <meta
          property="og:title"
          content="At Trustaff, we do things differently"
        />
        <meta
          property="og:description"
          content="Trustaff is much more than just a recruiting and staffing agency. We have a passion for the good business of putting people to work, and it shows every day in our relationships, teamwork, and the quality of our services."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta property="og:url" content="https://www.trustaff.com/about/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getAboutUsImageEdge('trustaff-about-us').node.childImageSharp.fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  At Trustaff, we do things differently
                </h1>
                <p className={`content`}>
                  Trustaff is much more than just a recruiting and staffing
                  agency. We have a passion for the good business of putting
                  people to work, and it shows every day in our relationships,
                  teamwork, and the quality of our services.
                </p>
                <p className={`content`}>
                  Privately owned &amp; headquartered in Cincinnati, Ohio,
                  Trustaff has experienced exponential growth over the last two
                  decades and has become one of the nation's most well-respected
                  travel healthcare agencies.
                </p>
              </div>
            </div>
            <div
              className={`column is-5 is-offset-1 is-hidden-mobile ${styles.icon}`}
            ></div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <AboutUsAboutSection
            fluid={
              getAboutUsImageEdge('about-us-sidebar-2').node.childImageSharp
                .fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <AboutUsClosingSection />
        </div>
        <HeroImageMini
          fluid={
            getAboutUsImageEdge('trustaff-team-collage').node.childImageSharp
              .fluid
          }
        ></HeroImageMini>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <AboutUsBulletsSection
            award1Fluid={
              getAboutUsImageEdge('awards-tjc').node.childImageSharp.fluid
            }
            award2Fluid={
              getAboutUsImageEdge('awards-sia').node.childImageSharp.fluid
            }
            award3Fluid={
              getAboutUsImageEdge('awards-inc5000').node.childImageSharp.fluid
            }
            award4Fluid={
              getAboutUsImageEdge('awards-deloitte100').node.childImageSharp
                .fluid
            }
            award5Fluid={
              getAboutUsImageEdge('awards-redi').node.childImageSharp.fluid
            }
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^about/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
