import React from 'react';
import Img from 'gatsby-image';
import styles from './about-us-about-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">The Trustaff story</h3>
          <div className="content">
            <p className="content">
              When Trustaff’s founding partners first joined forces in 2002,
              they had only a glimmer of the journey that lay ahead.
            </p>
            <p className="content">
              In the early 2000s, we recognized a growing need for healthcare
              professionals to step in and cover unexpected staffing shortages.
              Facilities needed them quickly and ready to hit the ground
              running.
            </p>
            <p className="content">
              Trustaff was a pioneer in the travel nursing industry, which is
              still our bread and butter today. From the very beginning, we had
              a head start on the skills we still use every day as recruiting
              and staffing continues to grow and evolve.
            </p>
          </div>
          <a
            href="https://ingenovishealth.wd1.myworkdayjobs.com/Ingenovishealth?CF_EEB_Recruiting_Company_Name_-_RE_Extended=bf0e13e56e41101468a249b38f680001"
            target="_blank"
            rel="noreferrer"
            className={`button is-primary`}
          >
            Join our team
          </a>
        </div>
        <div className={`column is-5 is-offset-1 is-hidden-mobile`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="Trustaff staffing company"
          />
        </div>
      </div>
    </div>
  );
};
