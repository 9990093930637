import React from 'react';
import { Link } from 'gatsby';
import styles from './about-us-closing-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Not just great jobs—great people
          </h3>
          <div className="content">
            <p className={`content`}>
              There’s more to finding a great staffing agency than just matching
              up lists. We connect one-on-one with each job seeker and client to
              ensure the highest level of service &amp; care. As a result, both
              our travelers and our facility partners have come to rely on
              Trustaff as a <b>trusted advisor</b>.
            </p>
            <p className="content">
              When you work with us, you are partnering with people who care
              about your success and are invested in your future.
            </p>
          </div>
          <Link
            to="/nurses/faq/"
            className={`button is-primary ${styles.spacing}`}
          >
            For travelers
          </Link>
          <Link
            to="/staffing-solutions/Working-with-trustaff/"
            className="button is-info"
          >
            For clients
          </Link>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile} ${styles.testimonial}`}
        >
          <p className={`content ${styles.testimonialtext}`}>
            “When I was looking for a job, my Trustaff recruiter went above and
            beyond to find me a good fit.
            <br />
            <br />I was so impressed that when my next manager decided we needed
            to hire, I immediately suggested we call Trustaff.”
          </p>
          <p className="content">— Jean</p>
        </div>
      </div>
    </div>
  );
};
