import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './about-us-bullets-section.module.scss';

export default ({
  award1Fluid,
  award2Fluid,
  award3Fluid,
  award4Fluid,
  award5Fluid,
}) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-12-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Our reputation speaks volumes
          </h3>
          <div className="content">
            <p className={`content`}>
              Trustaff is proud to be well-recognized both nationally and
              locally for our continued success and growth.
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                Accredited by{' '}
                <Link to="/joint-commission/">The Joint Commission</Link> with
                the <b>Gold Seal of Approval</b> for excellence in healthcare
                staffing
              </li>
              <li>
                Distinguished 11 times by{' '}
                <a
                  href="https://www.inc.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inc. Magazine
                </a>{' '}
                as one of the{' '}
                <b>INC 5000 fastest-growing privately held companies</b> in the
                country <em>(2007-2009, 2011-2018)</em>
              </li>
              <li>
                Named to{' '}
                <a
                  href="http://staffingindustry.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Staffing Industry Analysts
                </a>{' '}
                <b>Largest Staffing Firms in the US</b> <em>(2015-2020)</em>,{' '}
                <b>Largest Travel Nurse Staffing Firms</b> <em>(2015-2020)</em>,{' '}
                <b>Largest Allied Staffing Firms</b> <em>(2016-2020)</em>,{' '}
                <b>Largest Healthcare Staffing Firms</b> <em>(2015-2020)</em>,
                and <b>Fastest Growing Staffing Firms in the US</b>{' '}
                <em>(2016-2019)</em>,{' '}
              </li>
              <li>
                Named to the{' '}
                <a
                  href="https://www2.deloitte.com/us/en.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Deloitte
                </a>{' '}
                <b>Cincinnati USA 100</b>, recognizing record-setting regional
                companies <em>(2014-2020)</em>
              </li>
              <li>
                Recognized as one of the{' '}
                <b>largest and fastest-growing recruiting and staffing firms</b>{' '}
                in the Tri-State by the{' '}
                <a
                  href="https://www.bizjournals.com/cincinnati/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cincinnati Business Courier
                </a>{' '}
                <em>(2007-2019)</em>
              </li>
              <li>
                <a
                  href="https://redicincinnati.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  REDI
                </a>{' '}
                James A. Wuenker Growth award winner
              </li>
            </ul>
          </div>
        </div>
        <div className={`column is-5 is-offset-2`}>
          <Img
            fluid={award1Fluid}
            className={styles.awardsImages}
            alt="Joint Commission certified"
          />
          <Img
            fluid={award2Fluid}
            className={`${styles.awardsImages}`}
            alt="Staffing Industry Analysts"
          />
          <Img
            fluid={award3Fluid}
            className={`${styles.awardsImages}`}
            alt="Inc 5000"
          />
          <Img
            fluid={award4Fluid}
            className={`${styles.awardsImages}`}
            alt="Deliotte 100"
          />
          <Img
            fluid={award5Fluid}
            className={`${styles.awardsImages}`}
            alt="REDI"
          />
        </div>
      </div>
    </div>
  );
};
